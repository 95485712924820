class AddToCartWithQuantityButtons {
    init() {
        Array.from(document.getElementsByClassName('add-to-cart-button-with-quantity')).forEach(function (addToCartButton) {
            this.initButton(addToCartButton);
        }.bind(this));
    }

    initButton(addToCartButton) {

        let input = addToCartButton.querySelector('input[type=number]');
        let button = addToCartButton.querySelector('button.add-to-cart-button-submit');

        if (!input || !button) {
            console.warn('add-to-cart button is missing required elements.')
            return;
        }

        if (!input.getAttribute('data-partnumber') && !input.getAttribute('data-productId')) {
            console.warn('add-to-cart button is missing the requried data-partnumber and/or data-productId attribute');
            return;
        }

        if (input.getAttribute('data-partnumber') && input.getAttribute('data-productId')) {
            console.warn('add-to-cart button has both data-productId as data-partnumber attributes. Only one can exist.');
            return;
        }

        let min = (input.getAttribute('min') ? parseInt(input.getAttribute('min')) : null);
        let max = (input.getAttribute('max') ? parseInt(input.getAttribute('max')) : null);
        let steps = (input.getAttribute('step') ? parseInt(input.getAttribute('step')) : null);

        if (min && min > 1) {
            input.setAttribute('data-toggle', 'tooltip');
            input.setAttribute('data-placement', 'top');
            input.setAttribute('title', 'Min. aantal: ' + min);
            if ( $.isFunction($.fn.tooltip) ) {
                $(input).tooltip();
            }
        }

        this.addRemoveFromCartEvents(addToCartButton);

        input.addEventListener('change', (event) => {

            event.stopPropagation();

            let input = event.target;

            let currentValue = parseInt(input.value);
            var newValue = currentValue;

            if (currentValue < 0) {
                var newValue = 0;
            }

            if (min && newValue < min) {
                var newValue = min;
            }

            if (max && currentValue > max) {
                var newValue = max;
            }

            if (input.value && steps && (newValue % steps !== 0)) {
                var newValue = Math.ceil(currentValue / steps) * steps;
            }

            input.value = newValue;

        });

        button.addEventListener('click', (event) => {

            event.stopPropagation();

            let quantity = parseInt(input.value);

            if (
                (min && quantity < min)
                || (max && quantity > max)
                || (steps && (quantity % steps !== 0))) {
                return false;
            }

            if (button.parentElement.classList.contains('added-to-cart')) {
                // Already added to cart. Open checkout page.
                document.location.href="/winkelwagen";
            } else {
                this.addToCart(input, button);
            }

        });
    }

    async addToCart(input, button) {

        button.classList.add('loading');

        let productId = input.getAttribute('data-productId');
        let partnumber = input.getAttribute('data-partnumber');
        let token = input.getAttribute('data-token');
        let quantity = parseInt(input.value);

        let addedToCart = input.parentElement.classList.contains('added-to-cart');

        let requestBody = {
            productId: productId,
            partNumber: partnumber,
            quantity: quantity
        }

        var url;
        if (addedToCart) {
            url = '/public-api/cart/update/item';
        } else {
            url = '/public-api/cart/add/item';
        }

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        })
        .catch((error) => {
            button.classList.remove('loading');
            console.warn('Failed to add product to cart. Reason: ' + e);
        });

        const result = await response.json();

        if (result && result.status === 'success') {
            button.parentElement.classList.add('added-to-cart');
            button.classList.remove('loading');

            if (!input.getAttribute('data-productid') && result.productId) {
                input.removeAttribute('data-partnumber');
                input.setAttribute('data-productid', result.productId);
            }

        }

    }

    async removeFromCart(input) {

        let productId = input.getAttribute('data-productId');
        let token = input.getAttribute('data-token');

        let requestBody = {
            productId: productId,
        }

        var url = '/public-api/cart/delete/item';

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        })
        .catch((error) => {
            console.warn('Failed to remove product from cart. Reason: ' + e);
        });

        const result = await response.json();

        if (result && result.status === 'success') {
            input.parentElement.classList.remove('added-to-cart');
            input.value = input.getAttribute('min') ? parseInt(input.getAttribute('min')) : 1;
            this.hideRemoveFromCartButton(input.parentElement);
        }

    }

    showRemoveFromCartButton(addToCartButton) {
        if (!addToCartButton.classList.contains('added-to-cart')) {
            return;
        }

        var removeFromCartButton = addToCartButton.querySelector('button.add-to-cart-button-delete-button');
        if (removeFromCartButton) {
            $(removeFromCartButton).animate(
                {
                    'top': '100%'
                },
                {
                    'duration': 200
                }
            );
        }
    }

    hideRemoveFromCartButton(addToCartButton) {
        var removeFromCartButton = addToCartButton.querySelector('button.add-to-cart-button-delete-button');
        if (removeFromCartButton) {
            $(removeFromCartButton).animate(
                {
                    'top': '0%'
                },
                {
                    'duration': 200
                }
            );
        }
    }

    addRemoveFromCartEvents(addToCartButton) {

        let input = addToCartButton.querySelector('input[type=number]');
        let submitButton = addToCartButton.querySelector('button.add-to-cart-button-submit');
        var removeFromCartButton = addToCartButton.querySelector('button.add-to-cart-button-delete-button');

        if (removeFromCartButton) {

            addToCartButton.addEventListener('mouseenter', (event) => {
                event.stopPropagation();
                this.showRemoveFromCartButton(event.target);
            });

            addToCartButton.addEventListener('mouseleave', (event) => {
                event.stopPropagation();
                this.hideRemoveFromCartButton(event.target);
            });

            input.addEventListener('click', (event) => {
                event.stopPropagation();
                this.showRemoveFromCartButton(event.target);
            });

            input.addEventListener('blur', (event) => {
                this.hideRemoveFromCartButton(event.target);
            });

            removeFromCartButton.addEventListener('click', (event) => {
                event.stopPropagation();
                this.removeFromCart(input);
            });

        }

    }
}

window.addToCartWithQuantityButtons = new AddToCartWithQuantityButtons();
document.addEventListener('DOMContentLoaded', function() {
    addToCartWithQuantityButtons.init();
})