class ShowLoadingMessage  {
    body = $('body > .template');
    content = 'Bezig met laden';
    showLoading(box) {
        if (typeof box === 'undefined') {
            box = this.body;
        }
        box.addClass('hasLoadingState');
        $('body').append('<div class="loadingstate" id="loadingstate">' + this.content + '</div>');
    }

    hideLoading(box) {
        if (typeof box === 'undefined') {
            box = this.body;
        }
        box.removeClass('hasLoadingState');
        $('#loadingstate').fadeOut('fast', function () {
            $(this).remove();
        });
    }
    setContent(content) {
        this.content = content;
        $('#loadingstate').html(content);
    }
}

window.showLoadingMessage = new ShowLoadingMessage();