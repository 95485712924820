class CarSelector {
    formId =  'currentSelection';
    config = {};
    redirectTo = null;

    init(config) {
        this.config = config;
        const self = this;
        $('#optionWrapper').on('mouseover', '.modelselection-list.model a', function () {
            self.loadCarImage(this.dataset.seriesKey, $(this));
        });
    }

    getFormData(formId = null) {
        return formId ? new FormData(document.getElementById(formId)) : new FormData();
    }

    loadCarImage(seriesKey) {
        document.getElementById('modelImage').src = this.config.carModelImageEndPoint + '/' + seriesKey
    }

    selectOption(nextLevel, level, id) {
        const self = this;
        const formData = this.getFormData(this.formId);
        formData.append('level', level);
        formData.append(level, id);
        formData.append('nextLevel', nextLevel);

        const params = new URLSearchParams(formData);
        fetch(this.config.carSelectorEndPoint + '?' + params)
            .then(function (response) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        document.location.replace(self.redirectTo ? self.redirectTo : data.redirectTo)
                    });
                }

                return response.text().then(content => {
                    document.getElementById('optionWrapper').innerHTML = content;
                    self.refresh()
                });
            });
    }

    refresh() {
        const seriesKey = document.getElementById('seriesKey');
        if (seriesKey) {
            this.loadCarImage(seriesKey.value);
        }
    }

    findChassisNumber(identifier = '', keyCode = null) {
        if (keyCode && event.keyCode !== keyCode) {
            event.preventDefault();
            return;
        }

        const chassisNumber = document.getElementById(identifier + 'chassisNumber').value.slice(-7);
        if (!chassisNumber || chassisNumber.length !== 7) {
            document.getElementById(identifier + 'invalidChassisNumber').style.display = 'block';
            return false;
        }

        document.getElementById(identifier + 'invalidChassisNumber').style.display = 'none';
        document.getElementById(identifier + 'unknownChassisNumber').style.display = 'none';

        Cookies.set('searchChassisNumber', chassisNumber, { path: '' });

        const formData = this.getFormData();
        formData.append('chassisNumber', chassisNumber);
        formData.append('set', true);

        const params = new URLSearchParams(formData);

        const self = this;
        fetch(this.config.chassisNumberEndPoint + '?' + params).then(response => {
            return response.json().then(data => {
                if (data.status === 'success') {
                    location.replace(self.redirectTo ? self.redirectTo : data.redirectTo);
                    return;
                }

                if (data.status === 'multiple') {
                    window.$('#foundDoubleChassisNumbersModal').modal('show');
                    foundDoubleChassisNumbersModal.loadContent(data.data);
                    return;
                }

                document.getElementById(identifier + 'unknownChassisNumber').style.display = 'block';
            });
        });
    }

    findLicensePlate(identifier = '', keyCode = null) {
        if (keyCode && event.keyCode !== keyCode) {
            event.preventDefault();
            return;
        }

        const licensePlate = document.getElementById(identifier + 'licensePlate').value
        if (!licensePlate || (licensePlate.length !== 6 && licensePlate.length !== 8)) {
            document.getElementById(identifier + 'invalidLicensePlate').style.display = 'block';
            return false;
        }

        document.getElementById(identifier + 'invalidLicensePlate').style.display = 'none';
        document.getElementById(identifier + 'unknownLicensePlate').style.display = 'none';

        Cookies.set('searchLicensePlate', licensePlate, { path: '' });

        const formData = this.getFormData();
        formData.append('licensePlate', licensePlate);
        formData.append('set', true);

        const params = new URLSearchParams(formData);

        const self = this;
        fetch(this.config.licensePlateEndPoint + '?' + params).then(response => {
            return response.json().then(data => {
                if (data.status === 'success') {
                    location.replace(self.redirectTo ? self.redirectTo : data.redirectTo);
                    return;
                }

                document.getElementById(identifier + 'unknownLicensePlate').style.display = 'block';
            });
        });
    }

    clearSelectedCar() {
        const self = this;
        fetch(this.config.clearCarSelectionEndPoint, {method: "post"}).then(response => {
            return response.json().then(data => {
                if (data.status === 'success') {
                    location.reload();
                }
            });
        });
    }

    getStoredLicensePlateValue() {
        if (!Cookies.get('searchLicensePlate')) {
            return '';
        }

        return Cookies.get('searchLicensePlate');
    }

    getStoredChassisNumber() {
        if (!Cookies.get('searchChassisNumber')) {
            return '';
        }

        return Cookies.get('searchChassisNumber');
    }

    saveAndReloadPage(saveData = null) {
        let data;
        if (saveData) {
            data = new URLSearchParams(saveData);
        } else {
            const formData = this.getFormData(this.formId);
            data = new URLSearchParams(formData);
        }

        fetch(this.config.carSelectorEndPoint,
            {
                method: "post",
                body: data
            }
        ).then(response => {
            return response.json().then(data => {
                if (data.status === 'success') {
                    location.reload();
                }
            });
        });
    }
}

window.carSelector = new CarSelector();
