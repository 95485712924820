class Cart  {
    config = {}

    load(config) {
        this.config = config;
    }
    init() {
        const self = this;
        $('.quantity-controls__current').blur(function(eventData) {
            const element = $(this);
            const data = element.data();
            if(!eventData.target.checkValidity()) {
                eventData.target.reportValidity();
                return;
            }

            if(Number(element.val()) === Number(data.qty)) {
                return;
            }
            self.updateItemQuantity(data.id, Number(element.val()));
        });
        $('.quantity-controls__btn--minus').click(function() {
            const data = $(this).data();
            self.updateItemQuantity(data.id, data.qty - data.saleUnit);
        });
        $('.quantity-controls__btn--add').click(function() {
            const data = $(this).data();
            self.updateItemQuantity(data.id, data.qty + data.saleUnit);
        });
        $('.action-controls__btn--delete').click(function() {
            const data = $(this).data();
            self.deleteItem(data.id);
        });
        $('select.shipment-country-select').change(function() {
            self.post(
                self.config.setCountryEndPoint,
                {
                    country: this.value
                }
            );
        })
    }

    updateItemQuantity(productId, qty) {
        this.post(
            this.config.itemUpdateEndPoint,
            {
                productId: productId,
                quantity: qty
            }
        );
    }

    deleteItem(productId) {
        this.post(
            this.config.itemDeleteEndPoint,
            {
                productId: productId
            }
        );
    }

    addItem(productId, qty = 1) {
        this.post(
            this.config.itemAddEndPoint,
            {
                productId: productId,
                quantity: qty
            }
        );
    }

    post(url, data) {
        showLoadingMessage.showLoading();
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((response) => response.json())
            .then(function(json) {
                showLoadingMessage.hideLoading();
                if(json.status === 'success') {
                    document.location.reload();
                }
            });
    }
}

window.cart = new Cart();
