class AccountRegisterService {
    config = {}

    init(config) {
        this.config = config;
    }

    validateVat(vatNumber, callback) {
        fetch(this.config.vatValidateEndPoint, {
            method: "POST",
            body: JSON.stringify({vatNumber: vatNumber}),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((response) => response.json())
            .then(function (data) {
                callback(data);
            });
    }

    submit(registerForm, callback, data = null) {
        const self = this;
        const formData = new FormData(registerForm);
        if (data) {
            for (let key in data) {
                formData.append(key, data[key]);
            }
        }

        fetch(this.config.accountEndPoint, {
            method: "POST",
            body: new URLSearchParams(formData)
        })
            .then((response) => response.json())
            .then(function (data) {
                if (data.status === 'success') {
                    location.replace(self.config.afterSubmitRedirectPageUrl);
                    return;
                }

                callback(data);
            });
        return true;
    }

    addressSearch(countryId, zipcode, houseNumber, callable) {
        fetch(this.config.addressValidationEndPoint, {
            method: "POST",
            body: JSON.stringify({countryId: countryId, zipcode: zipcode, housenumber: houseNumber}),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((response) => response.json())
            .then(function (data) {
                callable(data);
            });
        return true;
    }
}

window.accountRegisterService = new AccountRegisterService();