class Login {
    errorId = 'loginModalError';

    setLoginApiUrl(loginApiUrl) {
        this.loginApiUrl = loginApiUrl;
    }

    submit(formId, redirectUrl = '/') {
        const form = document.getElementById(formId);
        if (!form.checkValidity()) {
            form.reportValidity();
            return false;
        }

        const errorElement = document.getElementById(this.errorId);
        const formData = new FormData(form);

        fetch(this.loginApiUrl, {
            method: "POST",
            body: new URLSearchParams(formData)
        })
            .then((response) => response.json())
            .then(function (data) {
                if (data.status === 'success') {
                    location.replace(redirectUrl);
                    return;
                }

                errorElement.classList.remove('hidden');
                errorElement.innerText = data.message;
            });
    }
}

window.login = new Login();