class RegisterForm {
    init(accountRegisterService) {
        this.accountRegisterService = accountRegisterService;

        const self = this;
        this.registerForm = document.getElementById('registerForm');
        this.addressPostcode = document.getElementById('addressPostcode');
        this.addressHouseNumber = document.getElementById('addressHouseNumber');
        this.addressCountry = document.getElementById('addressCountry');
        this.addressCity = document.getElementById('addressCity');
        this.addressStreet = document.getElementById('addressStreet');
        this.shippingAddressPostcode = document.getElementById('shippingAddressPostcode');
        this.shippingAddressHouseNumber = document.getElementById('shippingAddressHouseNumber');
        this.shippingAddressCountry = document.getElementById('shippingAddressCountry');
        this.shippingAddressCity = document.getElementById('shippingAddressCity');
        this.shippingAddressStreet = document.getElementById('shippingAddressStreet');

        this.registerForm.addEventListener("submit", function () {
            const form = self.registerForm;
            document.querySelectorAll('.form__error').forEach((element) => element.innerText = '');
            self.accountRegisterService.submit(this,
                function(data) {
                    if(data.status === 'exists') {
                        window.$('#loginModal').modal('show');
                        return;
                    }

                    if(data.status === 'token') {
                        form.querySelector('input[name="csrf_token"]').value = data.token;
                        document.getElementById('error.token').innerText = data.message;
                        return;
                    }

                    self.displayErrors(data.errors)
                },
                {
                    'address[street]': self.addressStreet.value,
                    'address[city]': self.addressCity.value,
                    'shippingAddress[street]': self.shippingAddressStreet.value,
                    'shippingAddress[city]': self.shippingAddressCity.value,
                }
            );
        });

        this.addressPostcode.addEventListener('change', function () {
            self.addressSearchAction(
                self.addressCity,
                self.addressStreet,
                self.addressPostcode,
                self.addressHouseNumber,
                self.addressCountry,
            );
        });

        this.addressHouseNumber.addEventListener('change', function () {
            self.addressSearchAction(
                self.addressCity,
                self.addressStreet,
                self.addressPostcode,
                self.addressHouseNumber,
                self.addressCountry,
            );
        });

        this.addressCountry.addEventListener('change', function () {
            self.addressSearchAction(
                self.addressCity,
                self.addressStreet,
                self.addressPostcode,
                self.addressHouseNumber,
                self.addressCountry,
            );
        });

        this.shippingAddressPostcode.addEventListener('change', function () {
            self.addressSearchAction(
                self.shippingAddressCity,
                self.shippingAddressStreet,
                self.shippingAddressPostcode,
                self.shippingAddressHouseNumber,
                self.shippingAddressCountry,
            );
        });

        this.shippingAddressHouseNumber.addEventListener('change', function () {
            self.addressSearchAction(
                self.shippingAddressCity,
                self.shippingAddressStreet,
                self.shippingAddressPostcode,
                self.shippingAddressHouseNumber,
                self.shippingAddressCountry,
            );
        });

        this.shippingAddressCountry.addEventListener('change', function () {
            self.addressSearchAction(
                self.shippingAddressCity,
                self.shippingAddressStreet,
                self.shippingAddressPostcode,
                self.shippingAddressHouseNumber,
                self.shippingAddressCountry,
            );
        });

        document.getElementById('vatNumber').addEventListener('change', function () {
            self.validateVatNumber(this.value);
        });

        document.getElementById('isCompany').addEventListener('change', function () {
            self.showSegment(document.getElementById('companyInformation'), this.checked);
        });

        document.getElementById('hasShippingAddress').addEventListener('change', function () {
            self.showSegment(document.getElementById('shippingAddress'), this.checked);
        });
    }

    validateVatNumber(vatNumber) {
        const vatInputError = document.getElementById('vatInputError');
        const vatInputSuccess = document.getElementById('vatInputSuccess');
        vatInputError.classList.remove('hidden');
        vatInputSuccess.classList.remove('hidden');
        vatInputError.style.display = 'none';
        vatInputSuccess.style.display = 'none';

        if (vatNumber.trim().length < 1) {
            return;
        }

        if (vatNumber.trim().length > 1) {
            vatInputError.style.display = 'block';
        }

        if (vatNumber.trim().length < 10) {
            return;
        }

        this.accountRegisterService.validateVat(vatNumber, function (data) {
            if (data.status === 'success' || data.status === 'ok') {
                vatInputError.style.display = 'none';
                vatInputSuccess.style.display = 'block';
            }
        })
    }

    addressSearchAction(cityElement, streetElement, postcodeElement, houseNumberElement, countryElement) {
        if (countryElement.options[countryElement.selectedIndex].text !== 'Nederland') {
            cityElement.removeAttribute('disabled');
            streetElement.removeAttribute('disabled');
            return;
        }

        cityElement.setAttribute('disabled', 'disabled');
        streetElement.setAttribute('disabled', 'disabled');
        if (postcodeElement.value.trim().length !== 6 || houseNumberElement.value.trim().length < 1) {
            return;
        }

        this.accountRegisterService.addressSearch(countryElement.value, postcodeElement.value.trim(), houseNumberElement.value.trim(),
            function (data) {
                const formData = data.data;

                if(formData.error !== undefined) {
                    cityElement.removeAttribute('disabled');
                    streetElement.removeAttribute('disabled');
                    document.getElementById('error.' + streetElement.id).innerHTML =
                        'Er kon geen adres gevonden worden op basis van uw postcode en huisnummer. U kunt deze alsnog zelf invullen.';
                }

                cityElement.value = formData.city ?? '';
                streetElement.value = formData.street ?? '';
            });
    }

    showSegment(element, checked) {
        element.classList.remove('hidden');
        if (checked) {
            this.setRequired(element, checked);
            element.style.display = 'block';
            return;
        }

        this.setRequired(element, checked);
        element.style.display = 'none';
    }

    setRequired(parentElement, isRequired = true) {
        const elementType = ['input', 'text'];
        const nodeList = parentElement.querySelectorAll('.required');
        for (var i = 0, length = nodeList.length; i < length; i++) {
            if (!nodeList[i].type || !elementType.includes(nodeList[i].getAttribute('type'))) {
                continue;
            }
            (isRequired ? nodeList[i].setAttribute("required", isRequired) : nodeList[i].removeAttribute("required"));
        }
    }

    displayErrors(errors) {
        for (let key in errors) {
            for (let segmentKey in errors[key]) {
                document.getElementById('error.' + key + '.' + segmentKey).innerText = errors[key][segmentKey];
            }
        }
    }
}

window.accountRegisterForm = new RegisterForm();