class DiagramController {

    static initialized = false;
    static diagramImage;
    static diagramZoomLevel;
    static diagramParts;
    static zoomLevel;
    static posX;
    static posY;
    static mouseX;
    static mouseY;
    
    static init() {
        DiagramController.diagramImage = document.getElementById('diagramImage');
        DiagramController.diagramZoomLevel = document.getElementById('diagramZoomLevel');
        DiagramController.diagramParts = document.getElementById('diagram-parts');

        DiagramController.zoomLevel = 1;

        DiagramController.posX = 0;
        DiagramController.posY = 0;
        DiagramController.mouseX = 0;
        DiagramController.mouseY = 0;

        DiagramController.diagramImage.addEventListener('mousedown', DiagramController.mouseDown, false);
        window.addEventListener('mouseup', DiagramController.mouseUp, false);

        DiagramController.initialized = true;
    }


    static zoomIn() {
        if (!DiagramController.initialized) {
            DiagramController.init();
        }

        DiagramController.zoomLevel++;
        DiagramController.diagramImage.style.transform = 'scale('+ DiagramController.zoomLevel +')';
        DiagramController.diagramZoomLevel.innerHTML = DiagramController.zoomLevel.toString();
    }

    static zoomOut() {
        if (!DiagramController.initialized) {
            DiagramController.init();
        }

        if (DiagramController.zoomLevel === 1) {
            return false;
        }
        DiagramController.zoomLevel--;
        DiagramController.diagramImage.style.transform = 'scale(' + DiagramController.zoomLevel + ')';
        DiagramController.diagramZoomLevel.innerHTML = DiagramController.zoomLevel.toString();

        // reset position
        if (DiagramController.zoomLevel === 1) {
            DiagramController.diagramImage.style.left = '';
            DiagramController.diagramImage.style.top = '';
        }
    }

    static mouseDown(e) {
        if (!DiagramController.initialized) {
            DiagramController.init();
        }

        e.preventDefault();
        DiagramController.posX = e.clientX - DiagramController.diagramImage.offsetLeft;
        DiagramController.posY = e.clientY - DiagramController.diagramImage.offsetTop;
        window.addEventListener('mousemove', DiagramController.moveElement, false);
    }

    static mouseUp() {
        if (!DiagramController.initialized) {
            DiagramController.init();
        }

        window.removeEventListener('mousemove', DiagramController.moveElement, false);
    }

    static moveElement(e) {
        if (!DiagramController.initialized) {
            DiagramController.init();
        }

        DiagramController.mouseX = e.clientX - DiagramController.posX;
        DiagramController.mouseY = e.clientY - DiagramController.posY;
        DiagramController.diagramImage.style.left = DiagramController.mouseX + 'px';
        DiagramController.diagramImage.style.top = DiagramController.mouseY + 'px';
    }

    // Function to scroll to a specific row by its ID
    static scrollToRow(partsPosition) {
        if (!DiagramController.initialized) {
            DiagramController.init();
        }

        // Get references to the container and the target row
        const targetRow = document.getElementById(partsPosition);

        if (targetRow) {
            DiagramController.diagramParts.scrollTop = targetRow.offsetTop;
        }
    }

}

window.DiagramController = DiagramController;