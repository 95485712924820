import { startStimulusApp } from '@symfony/stimulus-bridge';

export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./frontend/controllers',
    true,
    /\.(j|t)sx?$/
));

window.Cookies = require('js-cookie');

require('./frontend/scripts/classes/AddToCartWithQuantityButtons.js');
require('./frontend/scripts/classes/DiagramController.js');
require('./frontend/scripts/classes/CarSelector.js');
require('./frontend/scripts/classes/Cart.js');
require('./frontend/scripts/classes/CartSharing.js');
require('./frontend/scripts/classes/ShowLoadMessage.js');
require('./frontend/scripts/classes/Login.js');
require('./frontend/scripts/classes/AccountRegisterService.js');
require('./frontend/scripts/classes/AccountRegisterForm.js');
require('./frontend_app.less');
