class CartSharing {
    shareUrl = null;
    endPoint = null;

    init(endPoint) {
        this.endPoint = endPoint;
        const self = this;
        $('.cart__share-btn').click(function () {
            self.show();
        });
        $('.cart__share_link_copy_button').click(function () {
            self.copyShareCartLink();
        });
    }

    show() {
        const self = this;
        document.getElementById('cart-share-link-container').style.display = 'block';

        fetch(this.endPoint)
            .then(response => response.json())
            .then(data => data.status === 'error' ? '' : self.setShareUrl(data.url));
    }

    copyShareCartLink() {
        try {
            navigator.clipboard.writeText(this.getShareUrl());
            document.getElementById('cart-copied_span').style.visibility = 'visible';
        } catch (e) {
            console.log('Could not copy to clipboard. navigator.clipboard is only available over https.');
        }
    }

    setShareUrl(url) {
        this.shareUrl = url;
        document.getElementById('cart-share-link-input').value = url;
        $('.cart__share_link_whatsapp_button').attr('href', 'https://api.whatsapp.com/send?text=' + encodeURIComponent(url));
        $('.cart__share_link_email_button').attr('href', 'mailto:?' +
            'subject=' + encodeURIComponent('Uw Offenga gedeelde winkelwagen link') +
            '&body=' + encodeURIComponent("Uw Offenga winkelwagen link: \n" + url));
    }

    getShareUrl() {
        return this.shareUrl;
    }
}

window.cartSharing = new CartSharing();